import { DEFAULT_PAGINATION } from '@/enums'
import { EOrderStatus, ORDER_TITLE_ENUMS } from '@/enums/order'
import { ordersService } from '@/service'
import * as Sentry from '@sentry/vue'

import bridge from '@/bridge'

const getDefaultState = () => {
  return {
    status: '',
    identifier: `ALL-ALL-${new Date().valueOf()}`,
    cds: {}, // 倒计时订单管理
    currentOrderChannel: ORDER_TITLE_ENUMS[0],
    currentTabType: EOrderStatus.ALL,
    requestError: false,
    pagination: { ...DEFAULT_PAGINATION },
    orderList: [],
    noticeTipShow: false, // 通知提示展示
    currentOrder: null, // 目前被点击的订单
    data: {},
  }
}

export default {
  namespaced: true,
  state: getDefaultState(),
  mutations: {
    updateCurOrderChannel(state, payload) {
      state.currentOrderChannel = ORDER_TITLE_ENUMS.find(item => item.type === payload) || ORDER_TITLE_ENUMS[0]
    },
    updateCurrentTab(state, payload) {
      state.identifier = `${payload}-ALL-${new Date().valueOf()}`
      state.currentTabType = payload
    },
    updateNoticeTipShow(state, payload) {
      state.noticeTipShow = payload
    },
    updateCurrentOrder(state, payload) {
      state.currentOrder = payload
    }
  },
  actions: {
    /**
     * @description 获取orderList数据
     * @param {isNextPage} 是否是加载下一页
     * @param {isRefresh} 是否是刷新（非点击切换）
     */
    fetchOrderList({ state, dispatch }, payload) {
      const { isNextPage = false, isRefresh = false, isIBAdmin = false } = payload || {}
      if (!isNextPage) {
        state.pagination = { ...DEFAULT_PAGINATION }
      }
      if (state.pagination.isFinished) {
        return
      }
      if (!isRefresh && !isNextPage) {
        state.orderList = []
        state.requestError = false
      }
      state.pagination.isRefreshing = isRefresh || false
      state.pagination.isLoading = true
      state.pagination.pageSize = state.currentTabType === 'COMPLETED' && state.currentOrderChannel.type !== 'OFFLINE' ? 10000 : state.pagination.pageSize

      const params = {
        data: {
          orderTypes: ['ALL'], // 后端历史逻辑，不能下掉只能写死
          orderCategory: state.currentTabType,
          orderChannel: state.currentOrderChannel.type,
        },
        pageIndex: state.pagination.pageIndex,
        pageSize: state.pagination.pageSize
      }

      ordersService.queryOrderList(params, isIBAdmin).then(async res => {
        res = res || {}
        res.data = res.data || {}
        const { data: { data = [], total = 0 } } = res || {}
        if (isNextPage) {
          state.orderList = state.orderList.concat(data)
        } else {
          state.orderList = data
        }
        state.rawRes = res
        state.data = res.data // 兼容
        state.pagination.isFinished = (state.pagination.pageSize * state.pagination.pageIndex) >= total
        state.pagination.pageIndex += 1
        state.requestError = false
        state.pagination.isLoading = false
        state.pagination.isRefreshing = false
        if (!data.length) {
          dispatch('fetchOrderList', { isNextPage: true, isIBAdmin })
        }
      }).catch(async err => {
        if (err?.message === 'request cancel') {
          state.requestError = false
        } else {
          state.requestError = true
          state.pagination.isLoading = false
        }
        state.pagination.isLoading = false
        state.pagination.isRefreshing = false
        console.log(err)
        const { value: platform } = await bridge.currentPlatform()
        const login = await bridge.isLogin()
        // const userInfo = await bridge.getUserInfo()
        if (err.message !== 'request cancel') {
          Sentry.captureException(new Error(JSON.stringify(err)));
          Sentry.captureException(new Error(JSON.stringify({
            login: { type: login.type },
            // userInfo,
            platform
          })));
        } else {
          Sentry.captureMessage(err.message);
        }
      })
    }
  }
}
