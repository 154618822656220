import { createStore } from 'vuex'
import afterSalesState from './afterSalesState'
import assemblyServiceState from './assemblyServiceState.ts'
import cancellationState from './cancellationState'
import changeTimeWindowState from './changeTimeWindowState'
import checkReviewState from './checkReviewState'
import fetchTimeWindowState from './fetchTimeWindowState'
import globalState from './globalState.ts'
import orderDetailState from './orderDetailState'
import orderPostViewState from './orderPostViewState'
import orderRefundState from './orderRefundState'
import orderListState from './orderListState'
import rescheduleState from './rescheduleState'
import trackAndTraceState from './trackAndTraceState'
import extendedClickCollectPromptState from './extendedClickCollectPromptState'
import storesState from './storesState'
import yhtState from './yhtState'

export const store = createStore({
  modules: {
    globalState,
    orderListState,
    orderDetailState,
    cancellationState,
    rescheduleState,
    assemblyServiceState,
    checkReviewState,
    fetchTimeWindowState,
    changeTimeWindowState,
    storesState,
    orderRefundState,
    afterSalesState,
    orderPostViewState,
    trackAndTraceState,
    extendedClickCollectPromptState,
    yhtState,
  }
})

export default store
